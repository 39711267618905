import { Injectable } from '@angular/core';
import { StorageProvider } from 'src/app/providers/storage-service';

@Injectable({
  providedIn: 'root'
})

export class PropertiesProvider {

  constructor(private storageProvider: StorageProvider) {}

  public async setProperty(value: string): Promise<any> {
     await this.storageProvider.setToStorage('categoryId', value);
  }

  public getProperty(value: string): Promise<any> {
    return this.storageProvider.getFromStorage(value);
  }
}